import React from 'react';
import { graphql, PageProps } from 'gatsby';
import MainLayout from '@/layouts/MainLayout';
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';
import Pagination from '@/components/Pagination';
import { ListCard } from '@/components/cards';
import SectionHeader from '@/components/SectionHeader';
import { GatsbyImage } from 'gatsby-plugin-image';
import { OutlineButton } from '@/components/buttons';

export type BlogTemplatePageContext = {
  limit: number;
  skip: number;
  numPages: number;
  currentPage: number;
  feedBasePath: string;
  feedRootPath?: string;
};

const BlogTemplate = ({
  data: { allMarkdownRemark },
  pageContext,
}: PageProps<GatsbyTypes.BlogTemplateQuery, BlogTemplatePageContext>) => {
  const { t } = useTranslation();

  const { currentPage, numPages } = pageContext;

  const isFirstPage = currentPage === 1;

  const { nodes } = allMarkdownRemark;

  const blogs = currentPage === 1 ? nodes.slice(1) : nodes;

  const { title, cover, date, slug, description, lang } =
    nodes[0].frontmatter || {};

  if (!blogs.length) {
    return <div />;
  }

  return (
    <MainLayout>
      {isFirstPage && (
        <section
          className={`relative blog-landing flex justify-center ${
            lang === 'th' && 'ibm-plex-sans-thai'
          }`}
        >
          <div className="absolute bg-black bg-opacity-70 w-full h-full z-10" />
          <GatsbyImage
            image={cover?.childImageSharp?.gatsbyImageData}
            alt={title || ''}
            className="w-full h-full"
          />
          <div className="space-y-3 flex flex-col justify-center items-start absolute z-20 top-0 w-full h-full px-4 py-28 lg:py-32 md:px-6 lg:px-16 xl:px-28 2xl:px-0 max-w-7xl mx-auto">
            <span className="block text-xs md:text-sm text-neutral-200">
              {date}
            </span>
            <h2 className="text-2xl md:text-4xl font-semibold text-neutral-200 line-clamp-2 !leading-normal">
              {title}
            </h2>
            <p className="text-base md:text-xl line-clamp-3 text-neutral-200">
              {description}
            </p>
            <Link to={slug || ''} className="!mt-6 inline-block">
              <OutlineButton
                title={t('components.buttons.read-more')}
                className="hover:bg-secondary-main hover:text-neutral-900"
              />
            </Link>
          </div>
        </section>
      )}
      <section className="px-4 py-20 md:px-6 lg:px-16 xl:px-28 2xl:px-0 max-w-7xl mx-auto">
        {isFirstPage && <SectionHeader title="ALL POSTS" className="md:px-4" />}
        <div className="flex flex-col md:flex-row md:flex-wrap space-y-10 md:space-y-0 mt-10 md:mt-2">
          {blogs.map(({ frontmatter }, key) => {
            const { title, description, date, cover, slug, lang } =
              frontmatter || {};
            return (
              <ListCard
                title={title}
                excerpt={description}
                publishedAt={date}
                coverImage={cover?.childImageSharp?.gatsbyImageData}
                url={`/blog/${slug}`}
                key={key}
                className={`w-full md:w-1/2 md:py-8 md:px-4 xl:w-1/3 ${
                  lang === 'th' && 'ibm-plex-sans-thai'
                }`}
              />
            );
          })}
        </div>
        <Pagination
          currentPage={currentPage}
          numPages={numPages}
          feedBasePath="/blog"
          feedRootPath="/blog"
          className="mt-10 md:mt-2"
        />
      </section>
    </MainLayout>
  );
};

export default BlogTemplate;

export const pageQuery = graphql`
  query BlogTemplate($language: String!, $skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      limit: $limit
      skip: $skip
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      nodes {
        frontmatter {
          lang
          title
          description
          date(formatString: "DD MMMM YYYY")
          slug
          cover {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
    locales: allLocale(
      filter: { language: { eq: $language }, ns: { eq: "translation" } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

import React from 'react';
import classNames from 'classnames';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { Link } from 'gatsby-plugin-react-i18next';

type ListCardProps = {
  title?: string;
  excerpt?: string;
  coverImage?: IGatsbyImageData;
  publishedAt?: string;
  url?: string;
  className?: string;
};

const ListCard: React.FC<ListCardProps> = ({
  title,
  publishedAt,
  coverImage,
  excerpt,
  url,
  className,
}) => {
  return (
    <Link to={url || ''} className={classNames('block group', className)}>
      <article className="flex flex-col space-y-2.5">
        <div className="!aspect-w-16 !aspect-h-9 overflow-hidden">
          {coverImage && (
            <GatsbyImage
              alt={title || ''}
              image={coverImage}
              className="w-full h-full rounded-lg"
            />
          )}
        </div>
        <span className="block text-sm text-primary-main">{publishedAt}</span>
        <div className="flex flex-col space-y-1">
          <p className="text-lg font-medium text-primary-main line-clamp-2 group-hover:text-secondary-hover">
            {title}
          </p>
          <p className="text-neutral-600 line-clamp-2">{excerpt}</p>
        </div>
      </article>
    </Link>
  );
};

export default ListCard;

import React from 'react';
import classNames from 'classnames';
import { GatsbyImage } from 'gatsby-plugin-image';

type ProductCardType = {
  title?: string;
  desc?: string;
  icon?: GatsbyTypes.ImageSharp['gatsbyImageData'];
  className?: string;
};

const ProductCard: React.FC<ProductCardType> = ({
  title,
  desc,
  icon,
  className,
}) => {
  return (
    <div className={classNames(`flex flex-col rounded-xl p-6 h-60`, className)}>
      <div className="w-max h-max p-3 rounded-lg flex items-center justify-center bg-secondary-main bg-opacity-20">
        {icon && (
          <GatsbyImage alt={title || ''} image={icon} className="w-10 h-10" />
        )}
      </div>
      <div className="mt-4">
        <span className="text-2xl text-neutral-900 font-semibold mt-6">
          {title}
        </span>
        <p className="text-sm text-neutral-900 line-clamp-4 mt-1">{desc}</p>
      </div>
    </div>
  );
};

export default ProductCard;

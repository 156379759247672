import React from 'react';
import classNames from 'classnames';
import { GatsbyImage } from 'gatsby-plugin-image';

type InnovationCardType = {
  title?: string;
  desc?: string;
  icon?: GatsbyTypes.ImageSharp['gatsbyImageData'];
  className?: string;
};

const InnovationCard: React.FC<InnovationCardType> = ({
  title,
  desc,
  icon,
  className,
}) => {
  return (
    <div className={classNames(`relative mr-3`, className)}>
      <div className="absolute z-0 bg-secondary-border rounded-xl -top-1.5 left-1.5 w-full h-full" />
      <div className="relative z-10 flex flex-col bg-primary-main rounded-xl p-6 h-60">
        <div className="w-max h-max p-3 rounded-lg flex items-center justify-center bg-secondary-main bg-opacity-20">
          {icon && (
            <GatsbyImage alt={title || ''} image={icon} className="w-10 h-10" />
          )}
        </div>
        <div className="mt-4">
          <span className="text-2xl text-neutral-50 font-semibold mt-6">
            {title}
          </span>
          <p className="text-sm text-neutral-50 line-clamp-4 mt-1">{desc}</p>
        </div>
      </div>
    </div>
  );
};

export default InnovationCard;
